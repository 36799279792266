var exports = {};

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    exports = _typeof = function _typeof(obj) {
      return typeof obj;
    };

    exports["default"] = exports, exports.__esModule = true;
  } else {
    exports = _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    exports["default"] = exports, exports.__esModule = true;
  }

  return _typeof(obj);
}

exports = _typeof;
exports["default"] = exports, exports.__esModule = true;
export default exports;
export const __esModule = exports.__esModule;